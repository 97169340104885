// Version 2 - Timing sped up slightly

const homeBannerElements = gsap.utils.toArray("section.banner--home");
const homeBannerTimeline = gsap.timeline();
homeBannerElements.forEach((ele) => {
	let staggerAmount = 0;
	const bannerImages = ele.querySelectorAll(".background-image img");
	const bannerLogo = ele.querySelector(".banner-logo-container");
	const bannerHeading = ele.querySelector("h1");
	const bannerHeadingSpans = ele.querySelectorAll("h1 span");
	const bannerLine = ele.querySelector(".line-track .line");
	const introductionLineTrack = document.querySelector("section.introduction .line-track");
	gsap.set(introductionLineTrack, { opacity: 0 });
	gsap.set(bannerImages, { filter: "blur(30px)" });
	gsap.set(bannerLogo, { opacity: 0 });
	gsap.set(bannerLine, { height: 0 });
	homeBannerTimeline.addLabel("unblur");
	homeBannerTimeline.to(
		bannerImages,
		{
			filter: "blur(0px)",
			ease: "power1.out",
			delay: 0.3,
			duration: 1.5,
		},
		"unblur"
	);
	homeBannerTimeline.addLabel("logo", "unblur+=0.3");
	homeBannerTimeline.to(
		bannerLogo,
		{
			opacity: 1,
			duration: 1.25,
			ease: "power1.in",
			onComplete: () => {
				gsap.fromTo(
					bannerLogo,
					{
						opacity: 1,
					},
					{
						opacity: 0,
						scrollTrigger: {
							trigger: bannerLogo,
							start: "top 150px",
							end: "bottom 75px",
							scrub: 0.2,
							// markers: true,
						},
					}
				);
			},
		},
		"logo"
	);
	homeBannerTimeline.addLabel("strapline", "logo+=0.75");
	bannerHeadingSpans.forEach((spanEle) => {
		homeBannerTimeline.fromTo(
			spanEle,
			{
				y: "-0.25em",
			},
			{
				y: "0",
				duration: 1,
			},
			`strapline+=${staggerAmount}`
		);
		homeBannerTimeline.fromTo(
			spanEle,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: 1,
				ease: "power1.inOut",
				onComplete: () => {
					gsap.fromTo(
						spanEle,
						{
							opacity: 1,
						},
						{
							opacity: 0,
							scrollTrigger: {
								trigger: spanEle,
								start: "top 150px",
								end: "bottom 75px",
								scrub: 0.2,
								// markers: true,
							},
						}
					);
				},
			},
			`strapline+=${staggerAmount}`
		);
		staggerAmount += 0.3;
	});
	homeBannerTimeline.to(
		bannerLine,
		{ height: "100%", duration: 1.8, ease: "power1.out" },
		"strapline+=0.2"
	);
	homeBannerTimeline.to(
		introductionLineTrack,
		{
			opacity: 1,
			duration: 1,
			ease: "power1.out",
		},
		"-=0.5"
	);
});
