//LAZY LOAD ITEMS
var lazyLoadItems = 0;
var lazyLoadItemsSummary = [];
var lazyLoadOffset = 500;

//PAGE ANIM EVENTS ITEMS
var screenTriggerAnchor = 0.8;
var scrollDuration = 300;
var animScreenTopPos = 0; //anim out top position?
var animScreenBotPos = 0;
var animStaggerTotal = 0;
var animStaggerIncrement = 0.1;
var scrollAnimItems = 0;
var scrollAnimItemsSummary = [];
var scrollTriggerAnimItems = 0;
var scrollTriggerAnimItemsSummary = [];

//NAV BACKGROUND ITEMS
var navBackground;
var navBackgroundObject;
var navBackgroundAnimateDefaultPosition = 200;
var navBackgroundAnimateAnchor;

//SMOOTH SCROLL
var smoothScrollFriction = 0.15;
var smoothScrollEnabled = false;
var body;
var mainContainer;

var smoothScrollsx = 0;
var smoothScrollsy = 0;
var smoothScrolldx = smoothScrollsx;
var smoothScrolldy = smoothScrollsy;

//SCROLL SCALE
var scrollScaleItems = 0;
var scrollScaleItemsSummary = [];
var scrollScaleFriction = 0.17;

//SCROLL PARALLAX
var scrollParallaxItems = 0;
var scrollParallaxItemsSummary = [];

//DEFAULTS
var defaultDuration = 0.6;

//OTHER
var itemsToRefresh = [];

//JAVASCRIPT SCROLL DETECTION
var screenHeight = 0;
var bodyRect = 0;
var lastBodyHeight = 0;
var scrollTop = 0;
var screenBottomPos = 0;
var lastScrollTop = -1;
var scrollingDown = false;
var raf =
	window.requestAnimationFrame ||
	window.webkitRequestAnimationFrame ||
	window.mozRequestAnimationFrame ||
	window.msRequestAnimationFrame ||
	window.oRequestAnimationFrame;
var continuousRenderStarted = false;

var isMobileTablet = false;
if (window.innerWidth <= 1024) {
	isMobileTablet = true;
}

//BROWSER DETECTION
var IE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);

function AnimInit() {
	scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	screenHeight = window.innerHeight;
	bodyRect = document.body.getBoundingClientRect();
	lastBodyHeight = document.body.scrollHeight;
	screenBottomPos = screenHeight + scrollTop;
	itemsToRefresh = [];
	lastScrollTop = 1;

	//INTERVAL RENDER FUNCTIONS
	LazyLoadSetup();
	InstantAnimsSetup();
	ScrollAnimsSetup();
	NavBackgroundScroll();
	setInterval(IntervalRender, 40);

	window.addEventListener("resize", RefreshItemPositions);

	//CONTINUOUS RENDER FUNCTIONS
	SmoothScrollSetup();
	ScrollScaleSetup();
	ScrollParallaxSetup();
}

function InstantAnimsSetup() {
	let instantAnimItems = document.getElementsByClassName("anim-instant");
	if (instantAnimItems) {
		InstantAnims(instantAnimItems);
	}
}

var InstantAnims = function (instantAnimItems) {
	for (let i = 0; i < instantAnimItems.length; i++) {
		let element = instantAnimItems[i];
		if (element.classList.contains("anim-animated")) {
			continue;
		}

		let animType = element.getAttribute("data-anim");
		let animDelay = element.getAttribute("data-delay");
		let animDuration = element.getAttribute("data-duration");
		if (!animDuration) {
			animDuration = defaultDuration;
		}

		let fromObject = {
			opacity: 0,
		};
		let toObject = {
			opacity: 1,
			ease: "power1.inOut",
			delay: animDelay ? animDelay : 0,
		};

		var newElement;

		switch (animType) {
			case "fade-slide-up":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.y = "30px";
				toObject.y = "0px";
				break;
			case "fade-slide-down":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.y = "-30px";
				toObject.y = "0px";
				break;
			case "fade-slide-left":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.x = "30px";
				toObject.x = "0px";
				break;
			case "fade-slide-right":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.x = "-30px";
				toObject.x = "0px";
				break;
			case "block-reveal-right":
				newHtml = '<span class="anim-block-reveal">' + element.innerHTML + "</span>";
				element.innerHTML = newHtml;
				element.style.opacity = 1;

				newElement = element.querySelector(".anim-block-reveal");
				newElement.style.opacity = 0;
				element = newElement;
				scrollTriggerAnimItems[i] = newElement;
				fromObject.opacity = "1";
				fromObject.y = "100%";
				toObject.y = "0%";
				break;
			case "text-reveal-up":
				newHtml = '<span class="anim-text-reveal">' + element.innerHTML + "</span>";
				element.innerHTML = newHtml;
				element.style.opacity = 1;

				newElement = element.querySelector(".anim-text-reveal");
				newElement.style.opacity = 0;
				element = newElement;
				scrollTriggerAnimItems[i] = newElement;
				fromObject.opacity = "1";
				fromObject.y = "100%";
				toObject.y = "0%";
				break;
			case "text-reveal-right":
				newHtml = '<span class="anim-text-reveal">' + element.innerHTML + "</span>";
				element.innerHTML = newHtml;
				element.style.opacity = 1;

				newElement = element.querySelector(".anim-text-reveal");
				newElement.style.opacity = 0;
				element = newElement;
				scrollTriggerAnimItems[i] = newElement;
				fromObject.opacity = "1";
				fromObject.x = "-100%";
				toObject.x = "0%";
				break;
			default:
				animType = "fade";
				fromObject.opacity = "0";
				toObject.opacity = "1";
		}

		TweenLite.fromTo(element, parseFloat(animDuration), fromObject, toObject);

		instantAnimItems[i].classList.add("anim-animated");
	}
};

function ScrollAnimsSetup() {
	scrollTriggerAnimItems = document.getElementsByClassName("anim-scroll-trigger");
	scrollAnimItems = document.getElementsByClassName("anim-scroll");

	if (scrollTriggerAnimItems || scrollAnimItems) {
		ScrollAnims();
	}
}

var ScrollAnims = function () {
	scrollTriggerAnimItemsSummary = [];

	for (let i = 0; i < scrollTriggerAnimItems.length; i++) {
		let element = scrollTriggerAnimItems[i];
		let itemRect = element.getBoundingClientRect();
		let itemTop = itemRect.top.toFixed(0) - bodyRect.top.toFixed(0);
		let itemBot = itemRect.bottom.toFixed(0) - bodyRect.top.toFixed(0);
		let animType = element.getAttribute("data-anim");
		let animDelayAttribute = element.getAttribute("data-delay");
		let animDurationAttribute = element.getAttribute("data-duration");
		let animAnchor = element.getAttribute("data-anchor");
		let animAttributes = element.getAttribute("data-attributes");
		let animOffsetAttribute = element.getAttribute("data-offset");
		let fromPos = element.getAttribute("data-from");
		let toPos = element.getAttribute("data-to");

		let animDuration = animDurationAttribute ? animDurationAttribute : defaultDuration;
		let animDelay = animDelayAttribute ? animDelayAttribute : 0;
		let animOffset = animOffsetAttribute ? animOffsetAttribute : 0;
		let shouldAnimateIn = true;
		let shouldAnimateOut = false;
		let newHtml;

		let animStagger = false;
		if (animAttributes) {
			if (animAttributes.indexOf("stagger") >= 0) {
				animStagger = true;
			}
			if (animAttributes.indexOf("in-out") >= 0) {
				shouldAnimateOut = true;
			}
		}

		let fromObject = {};
		let toObject = {
			ease: "power1.inOut",
			delay: animDelay,
			immediateRender: false,
			paused: true,
		};

		switch (animType) {
			case "fade-slide":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.y = "30px";
				toObject.y = "0px";
				break;
			case "fade-slide-left":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.x = "30px";
				toObject.x = "0px";
				break;
			case "fade-slide-right":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.x = "-30px";
				toObject.x = "0px";
				break;
			case "fade-slide-up":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.y = "30px";
				toObject.y = "0px";
				break;
			case "fade-slide-down":
				fromObject.opacity = "0";
				toObject.opacity = "1";
				fromObject.y = "-30px";
				toObject.y = "0px";
				break;
			case "slide-down":
				fromObject.y = fromPos ? fromPos : "-15%";
				toObject.y = toPos ? toPos : "0%";
				break;
			case "text-reveal-up":
				newHtml = '<span class="anim-text-reveal">' + element.innerHTML + "</span>";
				element.innerHTML = newHtml;
				element.style.opacity = 1;

				var newElement = element.querySelector(".anim-text-reveal");
				newElement.style.opacity = 0;
				element = newElement;
				scrollTriggerAnimItems[i] = newElement;
				fromObject.opacity = "1";
				fromObject.y = "100%";
				toObject.y = "0%";
				break;
			case "overlay-reveal-right":
				element = OverlayRevealSetup(element, scrollTriggerAnimItems[i]);

				fromObject.opacity = "1";
				fromObject.x = "-100%";
				toObject.x = "0%";
				toObject.ease = Power3.easeOut;
				break;
			case "overlay-reveal-down":
				element = OverlayRevealSetup(element, scrollTriggerAnimItems[i]);

				fromObject.opacity = "1";
				fromObject.y = "100%";
				toObject.y = "100%";
				toObject.opacity = "1";
				toObject.ease = Power2.easeInOut;
				break;
			default:
				animType = "fade";
				fromObject.opacity = "0";
				toObject.opacity = "1";
		}

		let tween = TweenLite.fromTo(element, animDuration, fromObject, toObject);

		var itemArray = {
			element: element,
			itemTop: itemTop,
			itemBot: itemBot,
			screenAnchor: animAnchor ? animAnchor : screenTriggerAnchor,
			duration: animDuration,
			animatedIn: false,
			animatedOut: false,
			shouldAnimateIn: shouldAnimateIn,
			shouldAnimateOut: shouldAnimateOut,
			animType: animType,
			animFromObject: fromObject,
			animToObject: toObject,
			animTween: tween,
			animStagger: animStagger,
			animDelay: animDelay,
		};

		TriggerScrollAnims(i, element);

		scrollTriggerAnimItemsSummary.push(itemArray);
	}
	AddItemToBeRefreshed(scrollTriggerAnimItems, scrollTriggerAnimItemsSummary);

	//Constant Scroll Anims
	scrollAnimItemsSummary = [];
	for (let i = 0; i < scrollAnimItems.length; i++) {
		const element = scrollAnimItems[i];
		let itemRect = element.getBoundingClientRect();
		let itemTop = itemRect.top.toFixed(0) - bodyRect.top.toFixed(0);
		let itemBot = itemRect.bottom.toFixed(0) - bodyRect.top.toFixed(0);
		let animType = element.getAttribute("data-anim");
		let customScrollDuration = element.getAttribute("data-duration");
		let animAnchor = element.getAttribute("data-anchor");
		let animOffset = element.getAttribute("data-offset");
		let animAttributes = element.getAttribute("data-attributes");
		let animMinValue = element.getAttribute("data-min");
		let animMaxValue = element.getAttribute("data-max");

		let animReverse = false;
		if (animAttributes) {
			if (animAttributes.indexOf("reverse") >= 0) {
				animReverse = true;
			}
		}

		let shouldAnimateIn = false;
		let shouldAnimateOut = true;

		switch (animType) {
			case "fade-in":
				shouldAnimateIn = true;
				shouldAnimateOut = false;
				break;
			case "fade-out":
				shouldAnimateIn = true;
				shouldAnimateOut = true;
				break;
			default:
				animType = "fade";
				shouldAnimateIn = true;
				shouldAnimateOut = true;
		}

		let itemArray = {
			itemTop: itemTop,
			itemBot: itemBot,
			screenAnchor: animAnchor ? animAnchor : 1.1,
			offset: animOffset ? animOffset : 0,
			duration: customScrollDuration ? customScrollDuration : scrollDuration,
			animType: animType,
			reverse: animReverse,
			minValue: animMinValue ? animMinValue : 0,
			maxValue: animMaxValue ? animMaxValue : 1,
			shouldAnimateIn: shouldAnimateIn,
			shouldAnimateOut: shouldAnimateOut,
		};

		scrollAnimItemsSummary.push(itemArray);
	}
	AddItemToBeRefreshed(scrollAnimItems, scrollAnimItemsSummary);

	function OverlayRevealSetup(ele, scrollArrayItem) {
		let overlay = document.createElement("div");
		overlay.classList.add("anim-image-overlay");
		ele.appendChild(overlay);

		var newElement = ele.querySelector(".anim-image-overlay");
		ele = newElement;
		scrollArrayItem = newElement;

		return ele;
	}
};

var ConstantScrollAnims = function (itemID, item) {
	if (ElementIsInView(item)) {
		if (item.itemTop >= animScreenTopPos && item.shouldAnimateIn) {
			//animate in
			let newOpacity = Math.min((animScreenBotPos - item.itemTop) / item.duration, item.maxValue);
			if (item.reverse) {
				newOpacity = Math.max(1 - newOpacity, item.minValue);
			}
			scrollAnimItems[itemID].style.opacity = newOpacity;
		} else if (item.itemTop <= animScreenTopPos && item.shouldAnimateOut) {
			//animate out
			let newOpacity = Math.min((animScreenTopPos - item.itemTop) / item.duration, item.maxValue);
			if (item.reverse) {
				newOpacity = Math.max(newOpacity, item.minValue);
			}

			scrollAnimItems[itemID].style.opacity = newOpacity;
		} else if (item.itemBop >= animScreenBotPos && item.shouldAnimateOut) {
			//Not sure about this
			//animate out
			let newOpacity = (animScreenTopPos - item.itemTop) / item.duration;
			scrollAnimItems[itemID].style.opacity = 1 - newOpacity;
		}
	}
};

var TriggerScrollAnims = function (itemID, item) {
	if (!item.animatedIn) {
		if (ElementIsInView(item)) {
			//Animate In
			item.animatedIn = true; //Animated
			item.animatedOut = false;

			if (item.animStagger) {
				animStaggerTotal += animStaggerIncrement;
				item.animToObject["delay"] = parseFloat(item.animDelay) + parseFloat(animStaggerTotal);
				item.animTween = TweenLite.fromTo(
					item.element,
					item.duration,
					item.animFromObject,
					item.animToObject
				);
			}

			if (item.animTween) {
				item.animTween.play();
			}
		}
	}
	if (!item.animatedOut && item.shouldAnimateOut) {
		if (!ElementIsInView(item)) {
			//Animate Out

			item.animatedOut = true;
			item.animatedIn = false;

			if (item.animTween) {
				item.animTween.reverse();
			}
		}
	}
};

//LAZY LOAD IMAGES
//TODO This causes a problem for all elements after the img. If it's not a fixed size, after it loads it pushes the page down which
// means the elements after won't have the right positions anymore.
function LazyLoadSetup() {
	lazyLoadItems = document.querySelectorAll(".anim-lazy[data-src]");

	if (lazyLoadItems) {
		LazyLoad();
	}
}

var LazyLoad = function () {
	lazyLoadItemsSummary = [];
	for (let i = 0; i < lazyLoadItems.length; i++) {
		const element = lazyLoadItems[i];
		let itemRect = element.getBoundingClientRect();
		let itemTop = itemRect.top.toFixed(0) - bodyRect.top.toFixed(0);
		let itemBot = itemRect.bottom.toFixed(0) - bodyRect.top.toFixed(0);

		let itemArray = {
			itemTop: itemTop,
			itemBot: itemBot,
			complete: false,
		};

		//If Ele is currently in view
		if (itemTop <= screenBottomPos) {
			TriggerLazyLoad(i, itemArray);
		}

		lazyLoadItemsSummary.push(itemArray);
	}
	AddItemToBeRefreshed(lazyLoadItems, lazyLoadItemsSummary);
};

var TriggerLazyLoad = function (itemID, item) {
	if (!item.complete) {
		if (ElementIsInBottomView(item, lazyLoadOffset)) {
			lazyLoadItems[itemID].src = lazyLoadItems[itemID].getAttribute("data-src");
			lazyLoadItems[itemID].setAttribute("data-src", "");
			item.complete = true;
		}
	}
};

// ---- NAV SCROLL ----
var mobileIcon; // Added for Behave 2024
var NavBackgroundScroll = function () {
	navBackground = document.getElementsByClassName("anim-nav")[0];
	mobileIcon = document.getElementsByClassName("full-page-menu-icon")[0];
	if (!navBackground) {
		return null;
	}

	let animateAnchor = navBackground.getAttribute("data-anchor");
	let animateUsingAnchor = animateAnchor ? true : false;
	let animateAnchorPos;
	if (animateUsingAnchor) {
		animateAnchor = document.getElementById(animateAnchor);
		animateAnchorPos = animateAnchor.offsetTop - navBackground.offsetHeight;
	}

	let animatePosition = navBackground.getAttribute("data-position");
	animatePosition = animatePosition ? animatePosition : navBackgroundAnimateDefaultPosition;
	if (isMobileTablet) {
		animatePosition = animatePosition / 3;
	}

	let useBannerBottom = navBackground.getAttribute("data-banner-bottom");
	let bannerBottomPos;
	if (useBannerBottom) {
		let pageBanner = document.querySelector(".page-banner");
		if (!pageBanner) {
			useBannerBottom = false;
		} else {
			useBannerBottom = true;
			let bannerRect = pageBanner.getBoundingClientRect();
			bannerBottomPos =
				bannerRect.bottom.toFixed(0) - bodyRect.top.toFixed(0) - navBackground.offsetHeight;
		}
	}

	navBackgroundObject = {
		anchorPos: animateAnchorPos,
		useAnchor: animateUsingAnchor,
		animatePosition: animatePosition,
		useBanner: useBannerBottom,
		bannerBottomPos: bannerBottomPos,
	};

	TriggerNavBackgroundScroll();
};

var TriggerNavBackgroundScroll = function () {
	if (navBackgroundObject.useBanner) {
		if (scrollTop >= navBackgroundObject.bannerBottomPos) {
			navBackground.classList.add("scrolled");
			mobileIcon.classList.add("scrolled");
		} else {
			navBackground.classList.remove("scrolled");
			mobileIcon.classList.remove("scrolled");
		}
	} else if (navBackgroundObject.useAnchor) {
		if (scrollTop >= navBackgroundObject["anchorPos"]) {
			navBackground.classList.add("scrolled");
			mobileIcon.classList.add("scrolled");
		} else {
			navBackground.classList.remove("scrolled");
			mobileIcon.classList.remove("scrolled");
		}
	} else {
		if (scrollTop >= navBackgroundObject["animatePosition"]) {
			navBackground.classList.add("scrolled");
			mobileIcon.classList.add("scrolled");
		} else {
			navBackground.classList.remove("scrolled");
			mobileIcon.classList.remove("scrolled");
		}
	}
};

// --- SCROLL SMOOTH ---
var SmoothScrollSetup = function () {
	body = document.body;
	mainContainer = document.getElementById("smooth-scroll");

	if (IE || !mainContainer || isMobileTablet) {
		return null;
	}

	smoothScrollEnabled = true;

	body.style.height = mainContainer.clientHeight + "px";

	//TODO - change queryselector to use id or class instead and move below styling into css file
	mainContainer.style.position = "fixed";
	mainContainer.style.top = 0;
	mainContainer.style.left = 0;
	mainContainer.style.right = 0;
	mainContainer.style.width = "100%";

	window.addEventListener("scroll", SmoothScrollScroll);

	if (!continuousRenderStarted) {
		raf(ContinuousRender);

		continuousRenderStarted = true;
	}
};

var SmoothScrollScroll = function () {
	smoothScrollsx = window.pageXOffset;
	smoothScrollsy = window.pageYOffset;
};

var SmoothScrollTick = function () {
	smoothScrolldx = lerp(smoothScrolldx, smoothScrollsx, smoothScrollFriction);
	smoothScrolldy = lerp(smoothScrolldy, smoothScrollsy, smoothScrollFriction);

	smoothScrolldx = Math.floor(smoothScrolldx * 100) / 100;
	smoothScrolldy = Math.floor(smoothScrolldy * 100) / 100;

	mainContainer.style.transform = "translate(-" + smoothScrolldx + "px, -" + smoothScrolldy + "px)";
};

// ---- SCROLL SCALE ----
var ScrollScaleSetup = function () {
	scrollScaleItems = document.getElementsByClassName("anim-scale");
	if (scrollScaleItems) {
		ScrollScale();
	}
};

var ScrollScale = function () {
	scrollScaleItemsSummary = [];
	for (let i = 0; i < scrollScaleItems.length; i++) {
		const element = scrollScaleItems[i];
		let itemRect = element.getBoundingClientRect();
		let itemTop = itemRect.top.toFixed(0) - bodyRect.top.toFixed(0);
		let itemBot = itemRect.bottom.toFixed(0) - bodyRect.top.toFixed(0);
		let animAnchor = element.getAttribute("data-anchor");

		let itemArray = {
			itemTop: itemTop,
			itemBot: itemBot,
			screenAnchor: animAnchor ? animAnchor : 1,
			scrollZ: 0,
			scrollY: 0,
		};

		scrollScaleItemsSummary.push(itemArray);
	}

	if (!continuousRenderStarted) {
		raf(ContinuousRender);

		continuousRenderStarted = true;
	}

	AddItemToBeRefreshed(scrollScaleItems, scrollScaleItemsSummary);
};

var ScrollScaleTick = function (itemID, item) {
	if (ElementIsInView(item)) {
		let scrollDiff = animScreenTopPos - item.itemTop;
		let newZ = Math.max(scrollDiff / 5, 0);
		let newY = Math.max(scrollDiff / 5, 0);

		//item.scrollZ = (isMobileTablet) ? newZ : lerp(item.scrollZ, newZ, scrollScaleFriction);
		item.scrollY = isMobileTablet ? newY : lerp(item.scrollZ, newY, scrollScaleFriction);
		//scrollScaleItems[itemID].style.transform = 'perspective(1000px) translate3d(0px,' + item.scrollY + 'px,' + item.scrollZ + 'px)';
		scrollScaleItems[itemID].style.transform =
			"perspective(1000px) translate3d(0px," + item.scrollY + "px,0px)";
	}
};

// --- SCROLL PARALLAX ---
var ScrollParallaxSetup = function () {
	scrollParallaxItems = document.getElementsByClassName("anim-parallax");
	if (scrollParallaxItems) {
		ScrollParallax();
	}
};

var ScrollParallax = function () {
	scrollParallaxItemsSummary = [];
	for (let i = 0; i < scrollParallaxItems.length; i++) {
		const element = scrollParallaxItems[i];
		let itemRect = element.getBoundingClientRect();
		let itemTop = itemRect.top.toFixed(0) - bodyRect.top.toFixed(0);
		let itemBot = itemRect.bottom.toFixed(0) - bodyRect.top.toFixed(0);
		let animAnchor = element.getAttribute("data-anchor");

		let itemArray = {
			itemTop: itemTop,
			itemBot: itemBot,
			screenAnchor: animAnchor ? animAnchor : 1,
			scrollY: 0,
		};

		scrollParallaxItemsSummary.push(itemArray);
	}

	if (!continuousRenderStarted) {
		raf(ContinuousRender);

		continuousRenderStarted = true;
	}

	AddItemToBeRefreshed(scrollParallaxItems, scrollParallaxItemsSummary);
};

//UNDER DEVELOPMENT
var ScrollParallaxTick = function (itemID, item) {
	if (ElementIsInView(item)) {
		let scrollDiff = animScreenBotPos - item.itemTop;
		console.log(animScreenTopPos + " _ " + scrollDiff);
		let newY = Math.max(scrollDiff / 5, 0);

		scrollParallaxItems[itemID].style.transform = "translate3d(0px," + newY + "px,0px)";
	}
};

// --- EASING ---
function lerp(a, b, n) {
	return (1 - n) * a + n * b;
}

var AddItemToBeRefreshed = function (itemsArray, summaryArray) {
	itemsToRefresh.push({
		itemsArray: itemsArray,
		summaryArray: summaryArray,
	});
};

//TODO: Come back to this, don't think it resets the positions
var RefreshItemPositions = function () {
	SmoothScrollSetup();

	bodyRect = document.body.getBoundingClientRect();

	for (let x = 0; x < itemsToRefresh.length; x++) {
		const collection = itemsToRefresh[x];

		for (let i = 0; i < collection["itemsArray"].length; i++) {
			const element = collection["itemsArray"][i];

			let itemRect = element.getBoundingClientRect();
			collection["summaryArray"][i].itemTop = itemRect.top.toFixed(0) - bodyRect.top.toFixed(0);
			collection["summaryArray"][i].itemBop = itemRect.bottom.toFixed(0) - bodyRect.top.toFixed(0);
		}
	}
};

window.TriggerScrollDetection = function () {
	lastScrollTop = -1;
};

var ElementIsInBottomView = function (ele, offset) {
	animScreenBotPos = screenHeight + scrollTop;
	return ele.itemTop <= animScreenBotPos + offset;
};

var ElementIsInView = function (ele) {
	let offset = ele.offset ? ele.offset : 0;
	animScreenBotPos = screenHeight * ele.screenAnchor + scrollTop + offset;
	animScreenTopPos = screenHeight * (1 - ele.screenAnchor) + scrollTop + offset;
	return ele.itemTop <= animScreenBotPos && ele.itemBop >= animScreenTopPos;
};

var ElementIsInViewTest = function (ele) {
	return ele.itemTop <= screenBottomPos && ele.itemBop >= animScreenTopPos;
};

var AnimateElementsInView = function () {
	for (let i = 0; i < lazyLoadItemsSummary.length; i++) {
		TriggerLazyLoad(i, lazyLoadItemsSummary[i]);
	}

	for (let i = 0; i < scrollTriggerAnimItemsSummary.length; i++) {
		TriggerScrollAnims(i, scrollTriggerAnimItemsSummary[i]);
	}

	if (navBackground) {
		TriggerNavBackgroundScroll();
	}
};

var IntervalRender = function () {
	raf(function () {
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		if (lastScrollTop.toFixed(0) != scrollTop.toFixed(0)) {
			if (lastBodyHeight != document.body.scrollHeight) {
				RefreshItemPositions();
				lastBodyHeight = document.body.scrollHeight;
			}
			scrollingDown = lastScrollTop < scrollTop;
			if (smoothScrollEnabled) {
				lastScrollTop = smoothScrolldy;
			} else {
				lastScrollTop = scrollTop;
			}
			screenBottomPos = screenHeight + scrollTop;
			animStaggerTotal = -animStaggerIncrement;
			RefreshItemPositions();

			AnimateElementsInView();
		}
	});
};

var ContinuousRender = function () {
	if (smoothScrollEnabled) {
		SmoothScrollTick();
	}

	for (let i = 0; i < scrollScaleItemsSummary.length; i++) {
		ScrollScaleTick(i, scrollScaleItemsSummary[i]);
	}
	for (let i = 0; i < scrollParallaxItemsSummary.length; i++) {
		ScrollParallaxTick(i, scrollParallaxItemsSummary[i]);
	}
	for (let i = 0; i < scrollAnimItemsSummary.length; i++) {
		ConstantScrollAnims(i, scrollAnimItemsSummary[i]);
	}

	raf(ContinuousRender);
};

AnimInit();
